import {Button, Icon, useColorModeValue, VisuallyHidden} from "@chakra-ui/react";
import {SocialLinkType} from "./constants";

export const SocialButton = (props: SocialLinkType) => {
  const {label, href, icon, hoverBg} = props;

  return (
    <Button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={10}
      h={10}
      cursor={"pointer"}
      as={"a"}
      href={href}
      background={"white"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.15s ease"}
      _hover={{
        bg: hoverBg,
        color: "white",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      <Icon as={icon} />
    </Button>
  );
};
