import {Roboto, Source_Sans_3} from "next/font/google";

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["400", "700"],
});

const sourceSansPro = Source_Sans_3({
  subsets: ["latin"],
  display: "swap",
});

export const fonts = {
  heading: roboto.style.fontFamily,
  body: sourceSansPro.style.fontFamily,
  mono: roboto.style.fontFamily,
};
