export const colors = {
  brand: {
    100: "#1e87f0",
    200: "#1987d6",
    300: "#2575FC",
  },
  primary: {
    100: "#081d40",
    200: "#6ec1e4",
  },
};
