export const MAIN_ROUTES = {
  ABOUT_US: "/about-us",
  ADVANCE_NETWORK: "/advance-network",
  CONTROL_PANEL: "/control-panel",
  CONTACT_US: "/contact-us",
  EDUCATION: "/education",
  ENTERPRISE: "/enterprise",
  DATA_CENTER: "/data-center",
  FREE_TRIAL: "/free-trial",
  GOVERNMENT: "/government",
  HOME: "/",
  PARTNER_PROGRAM: "/partner-program",
  PRIVACY_POLICIES: "/privacy-policies",
  COOKIE_POLICIES: "/cookie-policies",
  PRICING: "/pricing",
  SALE: "/sale",
  SERVICES: "/services",
  TERMS_OF_SERVICE: "/terms-of-services",
};

export const SERVICES_ROUTES = {
  AUTOMATION: "/automation",
  BAREMETAL: "/baremetal",
  DISASTER_RECOVERY: "/disaster-recovery",
  HOSTED_EMULATORS: "/hosted-emulators",
  LAB_AS_SERVICE: "/lab-as-service",
  LEARNING_LABS: "/learning-labs",
  MANAGED_COLOCATION: "/managed-colocation",
  PROFESSIONAL_SERVICES: "/professional-services",
  PROOF_OF_CONCEPT: "/poc",
};

export const LEARNING_LAB_ROUTES = {
  CISCO_ACI: "/cisco-aci",
  CISCO_SDA: "/cisco-sda",
  CISCO_SD_WAN: "/cisco-sd-wan",
  CISCO_HYPERFLEX: "/cisco-hyperflex",
};

export const HOSTED_EMULATOR_ROUTES = {
  CML: "/cisco-modeling-labs",
  EVE_NG: "/eve-ng",
  GNS: "/gns3",
};
