import {defineStyle, defineStyleConfig} from "@chakra-ui/react";

const body1 = defineStyle({
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "28px",
});

const body2 = defineStyle({
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "24px",
});

const label1 = defineStyle({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "20px",
});

const label2 = defineStyle({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "18px",
});

const caption = defineStyle({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "15px",
});

export const Text = defineStyleConfig({
  baseStyle: {},
  sizes: {},
  variants: {
    body1,
    body2,
    label1,
    label2,
    caption,
  },
  defaultProps: {},
});

export const TEXT_VARIANTS = {
  BODY1: "body1",
  BODY2: "body2",
  CAPTION: "caption",
  LABEL1: "label1",
  LABEL2: "label2",
};
