"use client";

import {init as initApm} from "@elastic/apm-rum";
import packageJson from "../package.json";

type Props = {
  environment: string | undefined;
};

export const Apm = (props: Props) => {
  const {environment = "development"} = props;

  initApm({
    serviceName: packageJson.name,
    serverUrl: "https://fleet.onemindservices.com:8443",
    serviceVersion: packageJson.version,
    breakdownMetrics: true,
    environment: environment,
    centralConfig: true,
  });
  return <></>;
};
