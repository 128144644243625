import {defineStyle, defineStyleConfig} from "@chakra-ui/react";

const title1 = defineStyle({
  fontWeight: "700",
  fontSize: "60px",
  lineHeight: "72px",
});
const title2 = defineStyle({
  fontWeight: "700",
  fontSize: "56px",
  lineHeight: "68px",
});
const headLine1 = defineStyle({
  fontWeight: "700",
  fontSize: "48px",
  lineHeight: "56px",
});
const headLine2 = defineStyle({
  fontWeight: "700",
  fontSize: "40px",
  lineHeight: "50px",
});
const headLine3 = defineStyle({
  fontWeight: "700",
  fontSize: "36px",
  lineHeight: "44px",
});
const headLine4 = defineStyle({
  fontWeight: "700",
  fontSize: "32px",
  lineHeight: "40px",
});

const subTitle1 = defineStyle({
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "28px",
});
const subTitle2 = defineStyle({
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "24px",
});
const subTitle3 = defineStyle({
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "24px",
});
const subTitle4 = defineStyle({
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "20px",
});

export const Heading = defineStyleConfig({
  baseStyle: {},
  sizes: {},
  variants: {
    title1,
    title2,
    headLine1,
    headLine2,
    headLine3,
    headLine4,
    subTitle1,
    subTitle2,
    subTitle3,
    subTitle4,
  },
  defaultProps: {},
});

export const HEADING_VARIANTS = {
  HEADLINE1: "headLine1",
  HEADLINE2: "headLine2",
  HEADLINE3: "headLine3",
  HEADLINE4: "headLine4",
  SUBTITLE1: "subTitle1",
  SUBTITLE2: "subTitle2",
  SUBTITLE3: "subTitle3",
  SUBTITLE4: "subTitle4",
  TITLE1: "title1",
  TITLE2: "title2",
};
