import {useEffect, useState} from "react";
import {useUser} from "@auth0/nextjs-auth0/client";

export const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const {isLoading, user} = useUser();
  useEffect(() => {
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [user]);
  return {isAuthenticated, isLoading, user};
};
