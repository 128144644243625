import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {NAV_ITEMS, NavItem} from "./constants";
import {BiChevronRight} from "@onemind-services-llc/react-icons-ng/bi";

export const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((item) => (
        <Box
          key={item.label}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                as={"a"}
                p={2}
                href={item.href ?? "#"}
                fontWeight={500}
                fontSize={"19px"}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {item.label}
              </Box>
            </PopoverTrigger>

            {item.children && (
              <PopoverContent
                border={"1px solid #eee"}
                boxShadow={"sm"}
                bg={popoverContentBgColor}
                p={"10px"}
                rounded={"xl"}
                minW={"sm"}
                fontSize={"18px"}
              >
                <Stack>
                  {item.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({label, href, subLabel, children}: NavItem) => {
  const hasChildren = children && children.length > 0;
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Popover trigger={"hover"} placement={"right-start"}>
      <PopoverTrigger>
        <Box
          as={"a"}
          href={href ?? "#"}
          role={"group"}
          display={"block"}
          p={2}
          rounded={"md"}
          _hover={{bg: useColorModeValue("blue.50", "gray.900")}}
        >
          <Stack direction={"row"} align={"center"}>
            <Box>
              <Text
                transition={"all .3s ease"}
                _groupHover={{color: "blue.400"}}
                fontWeight={500}
              >
                {label}
              </Text>
              <Text fontSize={"md"}>{subLabel}</Text>
            </Box>
            {hasChildren && (
              <Flex
                transition={"all .3s ease"}
                transform={"translateX(-10px)"}
                opacity={0}
                _groupHover={{opacity: "100%", transform: "translateX(0)"}}
                justify={"flex-end"}
                align={"center"}
                flex={1}
              >
                <Icon as={BiChevronRight} color={"blue.400"} w={5} h={5} />
              </Flex>
            )}
          </Stack>
        </Box>
      </PopoverTrigger>

      {children && (
        <PopoverContent
          border={"1px solid #eee"}
          boxShadow={"sm"}
          bg={popoverContentBgColor}
          p={"10px"}
          rounded={"xl"}
          minW={"sm"}
        >
          <Stack>
            {children.map((child) => (
              <DesktopSubNav key={child.label} {...child} />
            ))}
          </Stack>
        </PopoverContent>
      )}
    </Popover>
  );
};
