"use client";

import {CacheProvider} from "@chakra-ui/next-js";
import {Box, ChakraProvider, ColorModeScript} from "@chakra-ui/react";
import {HubspotProvider} from "next-hubspot";
import {PricingContextProvider} from "@/context/pricingContext";
import {theme} from "@/theme/index";
import {PricingType} from "@/types/pricing";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
// import SaleModal from "./sale/components/saleModal";
// react slick for slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
  pricing: PricingType[];
  children: React.ReactNode;
};

export function Providers(props: Props) {
  const {children, pricing} = props;
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          duration: 6000,
          isClosable: true,
        },
      }}
    >
      <ColorModeScript initialColorMode={"light"} />
      <CacheProvider>
        <HubspotProvider>
          <PricingContextProvider pricing={pricing}>
            {/* <SaleModal /> */}
            <Navbar />
            <Box paddingX={{base: "5vw", md: "10vw"}} paddingTop={"4rem"}>
              {children}
            </Box>
            <Footer />
          </PricingContextProvider>
        </HubspotProvider>
      </CacheProvider>
    </ChakraProvider>
  );
}
