import {createContext, useContext} from "react";
import {PricingType} from "@/types/pricing";

export type PricingContextType = {
  pricing: PricingType[];
};

export const PricingContext = createContext<PricingContextType>({
  pricing: [],
});

type PricingContextProviderProps = {
  children: React.ReactNode;
  pricing: PricingType[];
};

export const PricingContextProvider = (props: PricingContextProviderProps) => {
  const {children, pricing} = props;
  return (
    <PricingContext.Provider
      value={{
        pricing,
      }}
    >
      {children}
    </PricingContext.Provider>
  );
};

export const usePricingContext = () => {
  const {pricing} = useContext(PricingContext);
  const cloudInstancePricing = pricing?.find((item) => item.id === "Cloud-Instances");
  const baremetalPricing = pricing?.find((item) => item.id === "Baremetal");
  return {baremetalPricing, cloudInstancePricing, pricing};
};
