"use client";

import {
  Box,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Image from "next/image";
import {SocialButton} from "./SocialButton";
import logo from "@/app/icon.png";
import backgroundImage from "public/footer/background.webp";
import {FOOTER_LINKS, FooterSection as _FooterSection, SOCIAL_LINKS} from "./constants";
import NewsLetter from "./newsletter";

const ListHeader = ({children}: {children: React.ReactNode}) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const FooterSection = ({header, items}: _FooterSection) => {
  return (
    <Stack align={"flex-start"}>
      <ListHeader>{header}</ListHeader>
      {items.map((item) => (
        <Flex
          as={"a"}
          href={item.href}
          key={item.label}
          _hover={{textDecoration: "underline"}}
          align={"center"}
          target={item.target ?? "_self"}
          textUnderlineOffset={"4px"}
        >
          {item.icon && <Icon as={item.icon} mr={2} />} {item.label}
        </Flex>
      ))}
    </Stack>
  );
};

const Footer = () => {
  return (
    <Box
      position={"relative"}
      bg={useColorModeValue("gray.50", "gray.900")}
      backgroundImage={backgroundImage.src}
      color={"white"}
      data-cy="section-footer"
    >
      <Container as={Stack} width={"100%"} maxW={"8xl"} px={8} py={10} marginX={"auto"}>
        <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 5}} spacing={8}>
          <Stack spacing={6}>
            <Image
              alt="logo"
              title="logo"
              data-cy="footer-logo"
              height={65}
              src={logo}
              width={200}
            />
            <Text fontSize={"sm"}>
              © 2016-{new Date().getFullYear()} CloudMyLab. All rights reserved.
            </Text>
            <Stack direction={"row"} spacing={6}>
              {SOCIAL_LINKS.map((social) => (
                <SocialButton key={social.label} {...social} />
              ))}
            </Stack>
          </Stack>
          {FOOTER_LINKS.map((section) => (
            <FooterSection
              key={section.header}
              header={section.header}
              items={section.items}
            />
          ))}
          <Stack align={"flex-start"} width={"auto"}>
            <NewsLetter />
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Footer;
