import {
  Box,
  Collapse,
  Flex,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {NAV_ITEMS, NavItem} from "./constants";
import {BiChevronRight} from "@onemind-services-llc/react-icons-ng/bi";

export const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{md: "none"}}>
      {NAV_ITEMS.map((item) => (
        <MobileNavItem key={item.label} {...item} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({label, children, href}: NavItem) => {
  const {isOpen, onToggle} = useDisclosure();
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        _hover={{
          textDecoration: "none",
        }}
        onClick={(e) => {
          if (children) {
            e.preventDefault();
            onToggle();
          }
        }}
      >
        {children ? (
          <Text fontWeight={600} color={linkColor} fontSize={"16px"}>
            {label}
          </Text>
        ) : (
          <Box
            as={"a"}
            href={href ?? "#"}
            style={{display: "flex", alignItems: "center"}}
          >
            <Text fontWeight={600} color={linkColor} fontSize={"16px"}>
              {label}
            </Text>
          </Box>
        )}
        {children && (
          <Icon
            as={BiChevronRight}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(90deg)" : "rotate(0deg)"}
            w={6}
            h={6}
          />
        )}
      </Flex>

      {children && (
        <Collapse in={isOpen} animateOpacity>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor={borderColor}
            align={"start"}
          >
            {children.map((child) => (
              <MobileNavItem key={child.label} {...child} />
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};
