import {IconType} from "@onemind-services-llc/react-icons-ng";
import {FaLinkedin, FaYoutube} from "@onemind-services-llc/react-icons-ng/fa";
import {Md2EmailOpen} from "@onemind-services-llc/react-icons-ng/md2";
import {BiBuoy, BiPhone} from "@onemind-services-llc/react-icons-ng/bi";
import {SiGooglemaps} from "@onemind-services-llc/react-icons-ng/si";
import {RiArticleFill} from "@onemind-services-llc/react-icons-ng/ri";
import {MAIN_ROUTES, SERVICES_ROUTES} from "@/constants/routes";

export type SocialLinkType = {
  label: string;
  href: string;
  icon: IconType;
  hoverBg: string;
};

type FooterItem = {
  label: string;
  href: string;
  icon?: IconType;
  target?: string;
};

export type FooterSection = {
  header: string;
  items: Array<FooterItem>;
};

export const SOCIAL_LINKS: Array<SocialLinkType> = [
  {
    label: "Blog",
    href: "https://blog.cloudmylab.com/",
    icon: RiArticleFill,
    hoverBg: "#000",
  },
  {
    label: "YouTube",
    href: "https://www.youtube.com/@cmlsupport7453",
    icon: FaYoutube,
    hoverBg: "#CD201F",
  },
  {
    label: "LinkedIn",
    href: "https://www.linkedin.com/company/cloudmylabcom/",
    icon: FaLinkedin,
    hoverBg: "#0A66C2",
  },
];

export const FOOTER_LINKS: Array<FooterSection> = [
  {
    header: "Contact Info",
    items: [
      {
        label: "sales@cloudmylab.com",
        href: "mailto:sales@cloudmylab.com",
        icon: Md2EmailOpen,
        target: "_blank",
      },
      {
        label: "support@cloudmylab.com",
        href: "mailto:support@cloudmylab.com",
        icon: BiBuoy,
        target: "_blank",
      },
      {
        label: "+1-408-201-9381",
        href: "tel:+1-408-201-9381",
        icon: BiPhone,
        target: "_blank",
      },
      {
        label: "11501 Dublin Bvld, Suite#200 Dublin CA 94568",
        href: "https://www.google.com/maps?q=11501+Dublin+Bvld,+Suite#200%20Dublin%20CA%2094568",
        icon: SiGooglemaps,
        target: "_blank",
      },
    ],
  },
  {
    header: "Services",
    items: [
      {
        label: "Hosted Emulators",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.HOSTED_EMULATORS}`,
      },
      {
        label: "Baremetal",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BAREMETAL}`,
      },
      {
        label: "Automation",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.AUTOMATION}`,
      },
      {
        label: "Managed Colocation",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.MANAGED_COLOCATION}`,
      },
      {
        label: "Disaster Recovery",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.DISASTER_RECOVERY}`,
      },
      {
        label: "Learning Labs",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LEARNING_LABS}`,
      },
      {
        label: "Proof of Concept",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.PROOF_OF_CONCEPT}`,
      },
      {
        label: "Lab as a Service",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LAB_AS_SERVICE}`,
      },
      {
        label: "Professional Services",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.PROFESSIONAL_SERVICES}`,
      },
    ],
  },
  {
    header: "Support",
    items: [
      {
        label: "About Us",
        href: `${MAIN_ROUTES.ABOUT_US}`,
      },
      {
        label: "Free Trial",
        href: `${MAIN_ROUTES.FREE_TRIAL}`,
      },
      {
        label: "Blog",
        href: "https://blog.cloudmylab.com",
        target: "_blank",
      },
      {
        label: "Knowledge Base",
        href: "https://support.cloudmylab.com/",
        target: "_blank",
      },
      {
        label: "Terms of Service",
        href: `${MAIN_ROUTES.TERMS_OF_SERVICE}`,
      },
      {
        label: "Privacy Policy",
        href: `${MAIN_ROUTES.PRIVACY_POLICIES}`,
      },
      {
        label: "Cookie Policy",
        href: `${MAIN_ROUTES.COOKIE_POLICIES}`,
      },
    ],
  },
];
