import Image from "next/image";
import {Link} from "@chakra-ui/next-js";
import logo from "@/app/icon.png";

const Logo = () => (
  <Link href="/">
    <Image
      alt="logo"
      title="logo"
      data-cy="navbar-logo"
      height={68}
      priority
      src={logo}
      width={208}
    />
  </Link>
);

export default Logo;
