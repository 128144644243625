import {
  HOSTED_EMULATOR_ROUTES,
  LEARNING_LAB_ROUTES,
  MAIN_ROUTES,
  SERVICES_ROUTES,
} from "@/constants/routes";

export type NavItem = {
  label: string;
  subLabel?: string;
  href?: string;
  children?: NavItem[];
};

export const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Services",
    href: `${MAIN_ROUTES.SERVICES}`,
    children: [
      {
        label: "Hosted Emulator",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.HOSTED_EMULATORS}`,
        subLabel: "Experience the power of the cloud",
        children: [
          {
            label: "EVE-NG",
            href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.HOSTED_EMULATORS}${HOSTED_EMULATOR_ROUTES.EVE_NG}`,
            subLabel: "Explore the power of EVE-NG",
          },
          {
            label: "GNS3",
            href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.HOSTED_EMULATORS}${HOSTED_EMULATOR_ROUTES.GNS}`,
            subLabel: "Explore the power of GNS3",
          },
          {
            label: "Cisco Modeling Labs",
            href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.HOSTED_EMULATORS}${HOSTED_EMULATOR_ROUTES.CML}`,
            subLabel: "Explore the power of CML2",
          },
        ],
      },
      {
        label: "Automation",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.AUTOMATION}`,
        subLabel: "Automate your network",
      },
      {
        label: "Baremetal",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BAREMETAL}`,
        subLabel: "Enhance your network simulation experience with baremetal",
      },
      {
        label: "Learning Labs",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LEARNING_LABS}`,
        subLabel: "Learn from the experts",
        children: [
          {
            label: "Cisco ACI",
            href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LEARNING_LABS}${LEARNING_LAB_ROUTES.CISCO_ACI}`,
            subLabel: "Learn about Cisco ACI",
          },
          {
            label: "Cisco HyperFlex",
            href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LEARNING_LABS}${LEARNING_LAB_ROUTES.CISCO_HYPERFLEX}`,
            subLabel: "Learn about Cisco HyperFlex",
          },
          {
            label: "Cisco SD-WAN",
            href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LEARNING_LABS}${LEARNING_LAB_ROUTES.CISCO_SD_WAN}`,
            subLabel: "Learn about Cisco SD-WAN",
          },
          {
            label: "Cisco Software Defined Access",
            href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LEARNING_LABS}${LEARNING_LAB_ROUTES.CISCO_SDA}`,
            subLabel: "Learn about Cisco Software Defined Access",
          },
        ],
      },
      {
        label: "Managed Colocation",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.MANAGED_COLOCATION}`,
        subLabel: "Let us manage your racks",
      },
      {
        label: "Disaster Recovery",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.DISASTER_RECOVERY}`,
        subLabel: "Protect your network from disaster",
      },
      {
        label: "Lab as a Service",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.LAB_AS_SERVICE}`,
        subLabel: "We will build your lab",
      },
      {
        label: "Proof of Concept",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.PROOF_OF_CONCEPT}`,
        subLabel: "Test before you buy",
      },
    ],
  },
  {
    label: "Features",
    children: [
      {
        label: "Data Centers",
        href: `${MAIN_ROUTES.DATA_CENTER}`,
        subLabel: "Explore our data centers",
      },
      {
        label: "Advanced Network",
        href: `${MAIN_ROUTES.ADVANCE_NETWORK}`,
        subLabel: "Explore our advanced network",
      },
      {
        label: "Professional Services",
        href: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.PROFESSIONAL_SERVICES}`,
        subLabel: "Explore our professional services",
      },
    ],
  },
  {
    label: "Pricing",
    children: [
      {
        label: "Pricing",
        href: `${MAIN_ROUTES.PRICING}`,
        subLabel: "See our pricing",
      },
      {
        label: "Sale",
        href: `${MAIN_ROUTES.SALE}`,
        subLabel: "See our current sale",
      },
    ],
  },
  {
    label: "Free Trial",
    href: `${MAIN_ROUTES.FREE_TRIAL}`,
  },
  {
    label: "Partner Program",
    href: `${MAIN_ROUTES.PARTNER_PROGRAM}`,
  },
  {
    label: "Company",
    children: [
      {
        label: "About Us",
        href: `${MAIN_ROUTES.ABOUT_US}`,
        subLabel: "Learn about our company",
      },
      {
        label: "Contact Us",
        subLabel: "Get in touch with us",
        href: `${MAIN_ROUTES.CONTACT_US}`,
      },
      {
        label: "Policies",
        subLabel: "Learn about our policies",
        children: [
          {
            label: "Privacy Policies",
            href: `${MAIN_ROUTES.PRIVACY_POLICIES}`,
            subLabel: "Learn about our privacy policies",
          },
          {
            label: "Cookie Policies",
            href: `${MAIN_ROUTES.COOKIE_POLICIES}`,
            subLabel: "Learn about our cookie policies",
          },
          {
            label: "Terms of Services",
            href: `${MAIN_ROUTES.TERMS_OF_SERVICE}`,
            subLabel: "Learn about our terms of services",
          },
        ],
      },
    ],
  },
];
