import {useHubspotForm} from "next-hubspot";
import {Box, Text, Spinner} from "@chakra-ui/react";
import "./styles.scss";
import {sendGTMEvent} from "@next/third-parties/google";

const NewsLetter = () => {
  const {loaded, error, formCreated} = useHubspotForm({
    region: "na1",
    portalId: "43951417",
    formId: "a76363fa-5cb4-4015-9e5a-4acd886cdafb",
    target: "#newsLetter-form",
    onFormSubmitted: () => {
      if (process.env.VERCEL_ENV === "production") {
        sendGTMEvent({
          event: "gtm.formSubmit",
          formId: "newsLetter-form",
        });
      }
    },
  });
  if (error) {
    return <Text>Error Loading form</Text>;
  }

  if (!loaded) {
    return <Spinner />;
  }

  if (formCreated) {
    return <Box id="newsLetter-form" />;
  }

  return <Text>Form not yet created.</Text>;
};

export default NewsLetter;
